<!-- src/components/OrderInfo.vue -->
<template>
  <div class="order-info">
    <div class="header">
      <span class="title">订单信息</span>
      <div class="toggle-buttons">
        <button
          class="toggle-button"
          :class="{ active: currentTab === 'waiting' }"
          @click="setTab('waiting')"
        >
          待接单
        </button>
        <button
          class="toggle-button"
          :class="{ active: currentTab === 'dispatching' }"
          @click="setTab('dispatching')"
        >
          已接单
        </button>
      </div>
    </div>

    <div class="content">
      <div v-if="currentTab === 'waiting'" class="tab-content">
        <p>待接单的订单列表或数据展示...（当前第 {{ currentPage }} 页）</p>
      </div>
      <div v-else class="tab-content">
        <p>已接单的订单列表或数据展示...（当前第 {{ currentPage }} 页）</p>
      </div>
    </div>

    <!-- 底部分页控制区域 -->
    <div class="pagination">
      <button
        class="page-button"
        @click="prevPage"
        :disabled="currentPage === 1"
      >
        上一页
      </button>
      <span>第 {{ currentPage }} 页 / 共 {{ totalPages }} 页</span>
      <button
        class="page-button"
        @click="nextPage"
        :disabled="currentPage === totalPages"
      >
        下一页
      </button>
      <div class="jump-to-page">
        <input
          type="number"
          v-model.number="jumpPage"
          min="1"
          placeholder="跳转页码"
        />
        <button class="jump-button" @click="jumpToPage">跳转</button>
      </div>
    </div>
  </div>
</template>
  
  <script setup>
import { ref, computed } from "vue";

// 当前选中的标签和页面数据
const currentTab = ref("waiting");
const currentPage = ref(1);
const jumpPage = ref(null);

// 假设每个页面有一定数量的数据，例如每页 5 条
const itemsPerPage = 5;

// 根据标签模拟不同的订单数据（实际数据来源可以通过API获取）
const waitingOrders = Array(12).fill("待接单订单数据"); // 示例：12 条待接单数据
const dispatchingOrders = Array(8).fill("已接单订单数据"); // 示例：8 条已接单数据

// 计算总页数，根据当前选中的标签确定数据源
const totalPages = computed(() => {
  const data =
    currentTab.value === "waiting" ? waitingOrders : dispatchingOrders;
  return Math.ceil(data.length / itemsPerPage);
});

// 切换标签
function setTab(tab) {
  currentTab.value = tab;
  currentPage.value = 1; // 切换标签时重置到第一页
}

// 上一页
function prevPage() {
  if (currentPage.value > 1) {
    currentPage.value -= 1;
  }
}

// 下一页
function nextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value += 1;
  }
}

// 跳转到指定页
function jumpToPage() {
  if (
    jumpPage.value &&
    jumpPage.value >= 1 &&
    jumpPage.value <= totalPages.value
  ) {
    currentPage.value = jumpPage.value;
    jumpPage.value = null;
  } else {
    alert("页码无效，请输入有效的页码");
  }
}
</script>
  
  <style scoped>
.order-info {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 80vh; /* 增加组件高度 */
  margin-bottom: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: bold;
  font-size: 1.2em;
}

/* 切换按钮样式 */
.toggle-buttons {
  display: flex;
  gap: 10px;
}

.toggle-button {
  background-color: #f0f0f0;
  border: none;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  color: #333;
}

.toggle-button:hover {
  background-color: #cccccc;
}

.toggle-button.active {
  background-color: #ffcc00;
  color: rgb(0, 0, 0);
}

.toggle-button.active:hover {
  background-color: #e0a800;
}

/* 内容区域样式 */
.content {
  padding: 10px;
  flex-grow: 1;
}

.tab-content {
  font-size: 1em;
  color: #666;
}

/* 分页区域样式 */
.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 10px;
}

.page-button {
  background-color: #ffcc00;
  color: rgb(0, 0, 0);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.page-button:disabled {
  background-color: #c8c8c8;
  cursor: not-allowed;
}

.jump-to-page {
  display: flex;
  align-items: center;
  gap: 5px;
}

.jump-to-page input {
  width: 100px;
  padding: 5px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.jump-button {
  background-color: #ffcc00;
  color: rgb(0, 0, 0);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.jump-button:hover {
  background-color: #e0a800;
}
</style>