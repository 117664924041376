<template>
  <div class="benefits-text">
    <div class="benefit-item">
      <svg
        style="margin: 10px"
        t="1731078436634"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="4703"
        width="42"
        height="42"
      >
        <path
          d="M832 470.912a21.333333 21.333333 0 0 0 21.333333-21.333333V220.16a21.034667 21.034667 0 0 0-17.365333-20.48c-1.536-0.810667-2.944-1.706667-4.736-2.133333L516.565333 128.298667c-2.048-0.426667-4.010667-0.256-5.973333-0.128h-0.042667c-0.896 0.085333-1.834667-0.128-2.688 0.042666-1.92-0.128-3.84-0.341333-5.845333 0.085334L192.682667 197.589333c-1.792 0.426667-3.2 1.322667-4.736 2.133334a21.034667 21.034667 0 0 0-17.28 20.48v325.333333c0 1.408 0.554667 2.645333 0.810666 3.968 8.32 198.101333 173.44 324.906667 337.066667 348.288A24.106667 24.106667 0 0 0 511.573333 898.005333l0.426667-0.085333 0.426667 0.085333a24.106667 24.106667 0 0 0 3.029333-0.213333c144.512-20.693333 310.912-128.853333 334.976-317.568a21.333333 21.333333 0 0 0-42.368-5.376c-21.034667 165.461333-167.68 260.906667-296.021333 280.234667C363.178667 832.768 213.333333 715.818667 213.333333 533.973333V236.714667l296.064-66.389334L810.666667 236.714667V449.578667a21.333333 21.333333 0 0 0 21.333333 21.333333"
          fill="#3D405D"
          p-id="4704"
        ></path>
      </svg>
      实时订单追踪，提升效率与顾客满意度
    </div>
    <div class="benefit-item">
      <svg
        style="margin: 10px"
        t="1731078998589"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="4852"
        width="42"
        height="42"
      >
        <path
          d="M799.829093 309.333929A180.607849 180.607849 0 0 0 671.146534 256.00064c-48.597293 0-94.293255 18.943984-128.682559 53.333289L490.496018 361.301886 438.613394 309.333929A180.607849 180.607849 0 0 0 309.888168 256.00064c-48.597293 0-94.293255 18.943984-128.682559 53.333289a182.186515 182.186515 0 0 0 0 257.365119l293.461089 294.911754a21.205316 21.205316 0 0 0 30.122641 0.085333l140.543883-139.007884a21.333316 21.333316 0 0 0-29.994641-30.293308l-125.397229 124.074563-278.570435-279.8931a139.519884 139.519884 0 0 1 0-197.077169c52.650623-52.650623 144.341213-52.650623 197.034503 0L475.434697 406.613848a21.333316 21.333316 0 0 0 30.165308 0l67.029278-67.029278c52.693289-52.693289 144.38388-52.693289 197.034502 0 26.367978 26.325311 40.831966 61.354616 40.831966 98.474585 0 37.205302-14.463988 72.19194-40.831966 98.559918l-33.237305 33.237305a21.333316 21.333316 0 1 0 30.165308 30.165309l33.279972-33.279973a180.693183 180.693183 0 0 0 53.333289-128.682559c0-48.597293-18.943984-94.293255-53.333289-128.682559"
          fill="#3C405D"
          p-id="4853"
        ></path>
      </svg>
      智能推荐系统，增加餐厅曝光，吸引食客
    </div>
    <div class="benefit-item">
      <svg
        style="margin: 10px"
        t="1731079113797"
        class="icon"
        viewBox="0 0 1040 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="5001"
        width="42"
        height="42"
      >
        <path
          d="M762.651364 721.625113l-244.51961-75.945717 334.929146-381.136569-90.452202 457.082286zM902.041621 174.986613a21.333067 21.333067 0 0 0-22.485052-2.005309L155.981614 538.032741a21.333067 21.333067 0 1 0 2.986629 39.338175L387.488053 651.951317a21.333067 21.333067 0 1 0 13.226502-40.532826L221.388796 552.795223l577.699446-291.40969-334.715816 380.79524 0.426661 0.426661a20.906405 20.906405 0 0 0-5.759928 13.653163l-0.085332 176.339129a21.333067 21.333067 0 0 0 42.666133 0l0.085332-147.368825 270.929947 84.180281a21.418399 21.418399 0 0 0 27.263659-16.21313L910.148187 196.149015a21.546397 21.546397 0 0 0-8.106566-21.162402z"
          fill="#3C405D"
          p-id="5002"
        ></path>
      </svg>
      专属餐厅页面，更新美食资讯，维护忠实顾客群
    </div>
  </div>

  <div class="form-container">
    <h2>通过手机号码登录</h2>
    <form @submit.prevent="onLogin" class="telephone-form">
      <input
        type="tel"
        placeholder="手机号码"
        v-model="loginForm.phone"
        required
      />
      <input
        type="password"
        placeholder="密码"
        v-model="loginForm.password"
        required
      />
      <button type="submit" class="login-button">登录</button>
    </form>
    <div class="extra-buttons">
      <button type="button" @click="$router.push('/register')">注册</button>
      <button type="button" @click="$router.push('/forgotpassword')">
        忘记密码？
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "UserLogin",
  data() {
    return {
      loginForm: {
        phone: "",
        password: "",
      },
    };
  },
  methods: {
    async onLogin() {
      // 检验手机号是否为11位
      if (this.loginForm.phone.length !== 11) {
        alert("请输入正确的11位手机号码");
        return;
      }
      // 检验密码最少长度为6位字符
      if (this.loginForm.password.length < 6) {
        alert("密码长度不能少于6位字符");
        return;
      }
      // 检查手机号是否已注册
      try {
        const existsResponse = await axios.get("https://eattogetchubby.top/merchant/exists1", {
          params: {
            merchantphonenumber: this.loginForm.phone
          }
        });
        
        // 根据返回结果进行判断
        if (!existsResponse.data) {
          alert("该电话号码尚未注册，请先注册！");
          return;
        }

        // 如果手机号已存在，检查密码是否正确
        const loginResponse = await axios.post("https://eattogetchubby.top/merchant/login", {
          merchantphonenumber: this.loginForm.phone,
          merchantpassword: this.loginForm.password
        });

        if (loginResponse.data.success) {
          this.$root.merchantId = loginResponse.data.merchantid; // 存储在 $root 上
          console.log("id是："+this.$root.merchantId);
          alert("登录成功！");
          this.$router.replace({ path: '/dashboard' });
        } else {
          alert("密码错误，请重试！");
        }
      } catch (error) {
        console.error("检查商家手机号时出错", error);
        alert("无法检查到商家手机号，请重试！");
        return;
      }
    },
  },
};
</script>

<style scoped>
.form-container {
  max-width: 800px;
  text-align: left;
  margin-left: 185px;
}

.form-container h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

.telephone-form {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 50%;
}

.telephone-form input[type="tel"],
.telephone-form input[type="password"] {
  width: 95%;
  padding: 10px;
  margin-top: 5px;
  font-size: 1.2em;
  border-radius: 10px;
  background-color: #000;
  border: 1px solid #fff;
  color: #fff;
}

.login-button {
  width: 100%;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 10px;
  background-color: #fff;
  color: #000;
  border: 2px solid #000000;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.login-button:hover {
  background-color: #000;
  color: #ffffff;
  border: 2px solid #5b5b5b;
}

.extra-buttons {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-top: 15px;
}

.extra-buttons button {
  width: 48%;
  padding: 10px;
  font-size: 1em;
  background-color: #fff;
  color: #000;
  cursor: pointer;
  border: 2px solid #000000;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.extra-buttons button:hover {
  background-color: #000;
  color: #ffffff;
  border: 2px solid #5b5b5b;
}

.benefits-text {
  padding: 0 25px;
  line-height: 3;
  font-size: 20px;
  font-weight: bold;
  color: #1f1b1b;
  text-align: left;
  margin-left: 150px;
  margin-top: 50px;
}

.benefit-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust spacing between items */
}

.benefit-icon {
  margin-right: 10px; /* Add spacing between icon and text */
  color: #ff7e00; /* Icon color */
}
</style>
