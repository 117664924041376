<!-- src/components/TodayData.vue -->
<template>
  <div class="today-data">
    <div class="header">
      <span class="title">今日数据</span>
      <span class="date">{{ currentDate }}</span>
    </div>
    <div class="data-grid">
      <div class="data-item" v-if="!isLoading">
        <p class="data-label">营业额</p>
        <p class="data-value">¥{{ totalprice }}</p>
      </div>
      <div class="data-item" v-if="!isLoading">
        <p class="data-label">有效订单</p>
        <p class="data-value">{{ completedOrders }}</p>
      </div>
      <div class="data-item" v-if="!isLoading">
        <p class="data-label">订单完成率</p>
        <p class="data-value">{{ (100*(completedOrders/(pendingOrders + dispatchingOrders + completedOrders + cancelledOrders))).toFixed(2) }}%</p>
      </div>
      <div class="data-item" v-if="!isLoading">
        <p class="data-label">平均客单价</p>
        <p class="data-value">¥{{ (totalprice/completedOrders).toFixed(1) }}</p>
      </div>
      <div class="data-item">
        <p class="data-label">新增用户</p>
        <p class="data-value">1</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from 'axios'

const pendingOrders = ref(0);
const dispatchingOrders = ref(0);
const completedOrders = ref(0);
const cancelledOrders = ref(0);
const totalprice=ref(0);

// 获取当前日期
const currentDate = ref(new Date().toISOString().split('T')[0])

// 定义获取订单统计信息的方法
const fetchOrderStats = async () => {
  try {
    
    // 获取已完成订单总额
    const totalResponse = await axios.get('https://eattogetchubby.top/order/totalprice/已完成');
    totalprice.value = totalResponse.data;

    // 获取待接单数量
    const pendingResponse = await axios.get('https://eattogetchubby.top/order/stats/待接单');
    pendingOrders.value = pendingResponse.data;

    // 获取已接单数量
    const dispatchingResponse = await axios.get('https://eattogetchubby.top/order/stats/已接单');
    dispatchingOrders.value = dispatchingResponse.data;

    // 获取已完成数量
    const completedResponse = await axios.get('https://eattogetchubby.top/order/stats/已完成');
    completedOrders.value = completedResponse.data;

    // 获取已取消数量
    const cancelledResponse = await axios.get('https://eattogetchubby.top/order/stats/已取消');
    cancelledOrders.value = cancelledResponse.data;

    console.log('Order stats fetched successfully:', { totalprice, pendingOrders, dispatchingOrders, completedOrders, cancelledOrders });
  } catch (error) {
    console.error('获取订单统计信息失败:', error);
  }
};

// 组件挂载时获取数据
onMounted(fetchOrderStats);

</script>

<style scoped>
.today-data {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.title {
  font-weight: bold;
  font-size: 1.2em;
}

.date {
  color: #888;
  font-size: 0.9em;
  margin-left: auto;
  margin-right: 15px;
}

.details-button {
  background-color: #ffcc00;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.details-button:hover {
  background-color: #e6b800;
}

.data-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
}

.data-item {
  background-color: #ffffe7;
  border-radius: 5px;
  text-align: center;
  padding: 10px;

}

.data-label {
  color: #666;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.data-value {
  font-weight: bold;
  font-size: 1.1em;
  color: #333;
}
</style>
