<template>
  <div id="app">
    <div class="split-screen">
      <div class="left"></div>
      <div class="right">
        <div class="logo-and-text-container">
          <img src="@/assets/FZU.png" alt="FZU Logo" class="logo-fzu" />
        </div>
        <h1>吃胖胖外卖商家中心</h1>
        <!-- 路由视图用于插入具体的页面内容 -->
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "AppLayout",
};
</script>
  
  <style scoped>
/* 以下样式复制自您提供的App.vue中的样式 */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-image: linear-gradient(to right, #f5f2bc, #ffed4e);
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.split-screen {
  display: flex;
  min-height: 100vh; /* 使容器高度占满整个视口 */
  overflow: hidden;
}

.left {
  flex: 0 0 35%; /* 设置左边固定宽度为30% */
  background: url("@/assets/face.png") no-repeat center center;
  background-size: cover; /* 背景图片覆盖整个区域 */
}

.right {
  position: relative;
  flex: 0 0 65%; /* 设置右边固定宽度为60% */
  background-image: linear-gradient(to right, #f5f5f5, #ffdb8c); /* 渐变背景 */
  display: flex;
  flex-direction: column;
}

.logo-and-text-container {
  display: flex;
  align-items: center;
  justify-content: center; /* 水平居中 */
}

.logo-fzu {
  height: 75px; /* Logo 高度 */
  flex-shrink: 0; /* 防止Logo缩小 */
  margin-left: 725px;
  margin-top: 50px;
}

h1 {
  margin-top: -90px;
  margin-bottom: 2px; /* 减小下边距 */
  font-size: 75px; /* 文字大小 */
  font-weight: bold; /* 字体加粗 */
  color: #000000; /* 文字颜色，可以根据需要修改 */
  text-align: left; /* 文本左对齐 */
  margin-left: 70px;
}
</style>
  