<template>
  <div class="menu-management">
    <div class="management-header">
      <div class="search-section">
        <label for="dishName">菜品名称:</label>
        <input
          type="text"
          id="dishName"
          v-model="searchQuery.foodname"
          placeholder="输入菜品名称"
        />

        <label for="saleStatus">售卖状况:</label>
        <select id="saleStatus" v-model="searchQuery.foodstatus">
          <option value="onSale">启售</option>
          <option value="soldOut">售空</option>
        </select>

        <button @click="searchDishes">查询</button>
        <button @click="resetSearch">显示全部</button>
      </div>

      <div class="management-actions">
        <button @click="bulkDelete">批量删除</button>
        <button @click="createNewDish">新建菜品</button>
      </div>
    </div>

    <table class="dishes-table">
      <thead>
        <tr>
          <th>
            <input
              type="checkbox"
              v-model="isAllSelected"
              @change="selectAllDishes"
            />
          </th>
          <th>菜品名称</th>
          <th>图片</th>
          <th>售价</th>
          <th>售卖状况</th>
          <th>最后操作时间</th>
          <th>操作</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="dish in filteredDishes" :key="dish.foodid">
          <td>
            <input type="checkbox" v-model="dish.isSelected" />
          </td>
          <td>{{ dish.foodname }}</td>
          <td><img :src="dish.foodpic" alt="菜品图片" /></td>
          <td>{{ dish.foodprice }}</td>
          <td>{{ dish.foodstatus === "onSale" ? "启售" : "售空" }}</td>
          <td>{{ dish.lastModified }}</td>
          <td>
            <button @click="editDish(dish.foodid)" class="btn">修改</button>
            <button @click="toggleSaleStatus(dish)" class="btn">
              {{ dish.foodstatus === "onSale" ? "售空" : "启售" }}
            </button>
            <button @click="deleteDish(dish.foodid)" class="delete-btn">删除</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- 新建菜品 Modal -->
  <div :class="{ modal: true, 'modal-visible': createModalVisible }">
    <div
      class="modal-content"
      :class="{ 'modal-content-visible': createModalVisible }"
    >
      <h3>新建菜品信息</h3>
      <form @submit.prevent="confirmCreate">
        <div class="form-group">
          <label for="newDishName">菜品名称:</label>
          <input type="text" id="newDishName" v-model="newDish.foodname" />
        </div>
        <div class="form-group">
          <label for="newDishImage">图片:</label>
          <input type="file" id="newDishImage" @change="handleImageUpload" />
        </div>
        <div class="form-group">
          <label for="newDishPrice">售价:</label>
          <input type="text" id="newDishPrice" v-model="newDish.foodprice" />
        </div>
        <div class="form-group">
          <label for="newDishTags">标签:</label>
          <input
            type="text"
            id="newDishTags"
            v-model="newDish.foodtype"
            placeholder="输入标签"
          />
        </div>
        <div class="form-group">
          <label for="newDishStatus">售卖状况:</label>
          <select id="newDishStatus" v-model="newDish.foodstatus">
            <option value="onSale">启售</option>
            <option value="soldOut">售空</option>
          </select>
        </div>
        <div class="modal-actions">
          <button
            type="button"
            @click="createModalVisible = false"
            class="cancel-button"
          >
            取消
          </button>
          <button type="submit" class="confirm-button">创建</button>
        </div>
      </form>
    </div>
  </div>

  <!-- 菜品编辑 Modal -->
  <div :class="{ modal: true, 'modal-visible': editModalVisible }">
    <div
      class="modal-content"
      :class="{ 'modal-content-visible': editModalVisible }"
    >
      <h3>修改菜品信息</h3>
      <form @submit.prevent="confirmEdit">
        <div class="form-group">
          <label for="editDishName">菜品名称:</label>
          <input type="text" id="editDishName" v-model="editingDish.foodname" />
        </div>
        <div class="form-group">
          <label for="editDishPrice">售价:</label>
          <input type="text" id="editDishPrice" v-model="editingDish.foodprice" />
        </div>
        <div class="modal-actions">
          <button type="button" @click="cancelEdit" class="cancel-button">
            取消
          </button>
          <button type="submit" class="confirm-button">保存</button>
        </div>
      </form>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';
export default {
  data() {
    return {
      isAllSelected: false, // 用于跟踪全选状态
      searchQuery: {
        foodname: "",
        foodstatus: "onSale",
      },
      filteredDishes: [], // 添加这个新数组
      createModalVisible: false, // 控制新建菜品模态窗口的显示
      newDish: {
        // 新菜品的数据模型
        merchantid: "",
        foodname: "",
        foodpic: "",
        foodprice: "",
        foodstatus: "onSale",
        foodtype: "",
      },
      editModalVisible: false,
      editingDish: {
        foodid: null,
        foodname: "",
        foodprice: "",
        foodstatus: "onSale",
      },
      dishes: [], // 用来接收后端的dish数组
    };
  },
  created() {
    // 初始化 filteredDishes 为所有菜品
    this.fetchDishes();
    // this.filteredDishes = this.dishes;
    // 设置每个菜品的最后操作时间为当前时间
    this.dishes.forEach((dish) => {
      dish.lastModified = this.getCurrentTime();
    });
    this.filteredDishes.forEach((dish) => {
      dish.lastModified = this.getCurrentTime();
    });
  },
  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        const formData = new FormData();
        formData.append('image', file);

        // 假设后端上传接口为 /upload-image
        axios.post('https://eattogetchubby.top/upload-image', formData,)
        .then(response => {
          // 假设后端返回上传文件的名称
          this.newDish.foodpic = response.data.filename;
        })
        .catch(error => {
          console.error(error.response.data);
          console.error(error.response.status);
          console.error(error.response.headers);
          console.error('Error uploading image:', error);
          alert('图片上传失败');
        });
      }
    },
    getFullImagePath(filename) {
      // 假设你的图片存放在服务器的/static/images/目录下
      return `/static/${filename}`;
    },
    async fetchDishes() {
      const merchantId = this.$root.merchantId; // 假设商户ID是已知的，之后再改
      axios.get(`https://eattogetchubby.top/food1/${merchantId}`)
        .then((response) => {
          this.dishes = response.data.map((dish) => {
             // const foodpicPath = dish.foodpic; // 数据库中的路径
             
  const foodpicPath = require("@/static/" + dish.foodpic.slice(8+58+7));
  console.log("Food Picture Path:", foodpicPath);

            return{
              foodid: dish.foodid,
            foodname: dish.foodname,
            foodpic: foodpicPath,
            foodprice: dish.foodprice,
            foodstatus: dish.foodstatus,
            isSelected: false,
            lastModified: this.getCurrentTime(), // 初始化最后操作时间
            }
          });
          this.filteredDishes = response.data.map((dish) => {
             // const foodpicPath = dish.foodpic; // 数据库中的路径
             
  const foodpicPath = require("@/static/" + dish.foodpic.slice(8+58+7));
  console.log("Food Picture Path:", foodpicPath);

            return{
              foodid: dish.foodid,
            foodname: dish.foodname,
            foodpic: foodpicPath,
            foodprice: dish.foodprice,
            foodstatus: dish.foodstatus,
            isSelected: false,
            lastModified: this.getCurrentTime(), // 初始化最后操作时间
            }
          });
        })
        .catch((error) => {
          console.error("Error fetching dishes: ", error);
          alert("无法加载菜品数据。");
        });
    },
    selectAllDishes() {
      this.filteredDishes.forEach((dish) => {
        dish.isSelected = this.isAllSelected;
      });
      this.dishes.forEach((dish) => {
        dish.isSelected = this.isAllSelected;
      });
    },
    searchDishes() {
      if (
        this.searchQuery.foodname.trim() === "" &&
        this.searchQuery.foodstatus === "onSale"
      ) {
        // 如果搜索框为空并且售卖状况为默认值，则显示所有菜品
        this.resetSearch();
      } else {
        // 使用 trim() 来去除输入两端的空白字符
        const searchName = this.searchQuery.foodname.trim().toLowerCase();
        const searchStatus = this.searchQuery.foodstatus;
        // 过滤 dishes 数组
        this.filteredDishes = this.dishes.filter((dish) => {
          // 检查菜品名称是否包含搜索的文本
          const nameMatches = dish.foodname.includes(searchName);
          // 检查菜品状态是否匹配选择的状态
          const statusMatches = dish.foodstatus === searchStatus;
          // 如果没有输入名称，则不用过滤名称
          const nameQueryEmpty = searchName === "";
          // 当名称和状态都匹配时，或者只有状态匹配但名称查询为空时，返回 true
          return (
            (nameMatches && statusMatches) || (nameQueryEmpty && statusMatches)
          );
        });
      }
    },
    bulkDelete() {
      if (this.dishes.some((dish) => dish.isSelected)) {
        if (confirm("确定要删除选中的菜品吗？")) {
          this.dishes = this.dishes.filter((dish) => !dish.isSelected);
          // 更新 filteredDishes 以反映删除操作
          this.filteredDishes = this.dishes.filter((dish) => dish);
          // 重置全选状态
          this.isAllSelected = false;
        }
      } else {
        alert("请至少选择一个菜品。");
      }
    },
    createNewDish() {
      // 设置新菜品的默认值
      this.newDish = {
        foodname: "",
        foodpic: "",
        foodprice: "",
        foodstatus: "onSale",
        foodtype: "",
      };
      // 显示新建菜品的模态窗口
      this.createModalVisible = true;
    },

    confirmCreate() {
      // 验证菜品信息是否完整
      if (!this.newDish.foodname || !this.newDish.foodprice || !this.newDish.foodtype) {
        alert("请填写完整的菜品信息。");
        return;
      }

      // 将价格转换为浮点数，并保留两位小数
      const priceFloat = parseFloat(this.newDish.foodprice);
      if (isNaN(priceFloat)) {
        alert("请输入有效的价格。");
        return;
      }
      const formattedPrice = priceFloat.toFixed(2);

      // 添加新菜品到列表
      const nowmerchantid = 1; // 假设商户ID是已知的，之后再改
      const newDish = {
        foodid: Date.now(),
        merchantid: nowmerchantid,
        foodname: this.newDish.foodname,
        foodpic: this.newDish.foodpic || '@/assets/chubby_w.png', // 如果没有图片地址，则使用默认图片
        foodprice: formattedPrice, // 使用格式化后的价格
        foodstatus: this.newDish.foodstatus,
        foodtype: this.newDish.foodtype,
        isSelected: false,
        lastModified: this.getCurrentTime(), // 设置当前时间
      };
      this.dishes.push(newDish);
      this.filteredDishes.push(newDish);

      alert("菜品创建成功！")
      // 关闭模态窗口
      this.createModalVisible = false;

      // 重置新菜品数据模型，以便下次创建
      this.newDish = { foodname: "", foodpic: "", foodprice: "", foodstatus: "onSale",foodtype: "" };
      
      
      /*
      axios.post('http://localhost:8080/food',{
        foodname: newDish.foodname,
        foodprice: newDish.foodprice,
        merchantid: newDish.merchantid,
        foodpic: newDish.foodpic,
        foodtype: newDish.foodtype,
        foodstatus: newDish.foodstatus
      }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
      .then(response => {
        // 请求成功，提示用户并刷新菜品列表
        alert(response.data);
        this.fetchDishes(); // 重新加载菜品列表
        this.createModalVisible = false; // 关闭模态窗口
      })
      .catch(error => {
        // 请求失败，提示用户
        console.error('Error creating dish:', error);
        alert('创建菜品失败');
      });
*/

      
    },

    updateDishName(dish) {
      console.log(dish);
      axios.put(`https://eattogetchubby.top/food/name`, {
        foodid: dish.foodid,
        foodname: dish.foodname,
        foodprice: dish.foodprice
      }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
      .then(response => {
        alert(response.data);
        this.fetchDishes(); // 重新加载菜品列表
      })
      .catch(error => {
        console.error('Error updating dish name:', error);
        alert('菜品名称更新失败');
      });
      
    },
    editDish(dishId) {
      const dish = this.dishes.find((d) => d.foodid === dishId);
      if (dish) {
        this.editingDish = { ...dish }; // 复制菜品信息到编辑对象

        this.editModalVisible = true; // 显示编辑模态窗口
      }
    },

    confirmEdit() {
      const dishIndex = this.dishes.findIndex(
        (d) => d.foodid === this.editingDish.foodid
      );
      if (dishIndex !== -1) {
        this.updateDishName(this.editingDish);
        this.dishes.splice(dishIndex, 1, { ...this.editingDish }); // 更新菜品信息
        this.editModalVisible = false; // 关闭模态窗口
      }
    },
    cancelEdit() {
      this.editModalVisible = false;
    },
    todeleteDish(foodid) {
      axios.delete(`https://eattogetchubby.top/food/delete/${foodid}`)
      .then(response => {
        alert(response.data);
        this.fetchDishes(); // 重新加载菜品列表
      })
      .catch(error => {
        console.error('Error deleting dish:', error);
        alert('删除失败');
      });
    },
    deleteDish(foodid) {
      if (confirm("确定要删除该菜品吗？")) {
        const index = this.dishes.findIndex((dish) => dish.foodid === foodid);
        if (index !== -1) {
          this.todeleteDish(foodid);
          this.dishes.splice(index, 1); // 使用 splice 删除元素
          // 更新 filteredDishes 以反映删除操作
          this.filteredDishes = this.dishes.filter((dish) => dish);
        }
      }
    },
    changeStatus(dish) {
      axios.put(`https://eattogetchubby.top/food/status`, {
        foodid: dish.foodid,
        foodstatus: dish.foodstatus
      }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
      .then(response => {
        alert(response.data);
        this.fetchDishes(); // 重新加载菜品列表
      })
      .catch(error => {
        console.error('Error updating dish status:', error);
        alert('菜品状态更新失败');
      });
    },
    toggleSaleStatus(dish) {
      const action = dish.foodstatus === "onSale" ? "售空" : "启售";
      if (confirm(`确定要将这个菜品设置为${action}吗？`)) {
        dish.foodstatus = dish.foodstatus === "onSale" ? "soldOut" : "onSale";
        this.changeStatus(dish);
        this.updateLastModified(dish); // 更新最后操作时间
        this.searchQuery.foodstatus = "onSale";
      }
    },
    getCurrentTime() {
      const now = new Date();
      return now.toISOString().slice(0, 19).replace("T", " ");
    },
    updateLastModified(dish) {
      dish.lastModified = this.getCurrentTime();
    },
    resetSearch() {
      // 重置搜索条件
      this.searchQuery.foodname = "";
      this.searchQuery.foodstatus = "onSale"; // 或者你可以根据需要设置为任何默认值
      // 重置 filteredDishes 以显示所有菜品
      this.filteredDishes = this.dishes;
    },
  },
};
</script>
  
<style scoped>
.menu-management {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.management-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.btn {
  background-color: #ffc107;
  color: #212529;
  border: 1px solid #ddd;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9em;
}

.btn:hover {
  background-color: #e0a800;
}

.delete-btn {
  background-color: #ca2f31;

  color: rgb(255, 255, 255);
  border: 1px solid #ddd;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9em;
}

.delete-btn:hover {
  background-color: rgb(181, 53, 53);
  color: rgb(235, 235, 235);
}

.search-section {
  display: flex;
  gap: 15px;
  align-items: center;
}

.search-section label {
  font-weight: 600;
  color: #333;
}

.search-section input,
.search-section select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.search-section button {
  background-color: #ffc107;
  color: #212529;
  border: none;
  padding: 6px 12px;
  margin-right: 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1em;
}

.search-section button:hover {
  background-color: #e0a800;
}

.management-actions button {
  background-color: #ffc107;
  color: #212529;
  border: none;
  padding: 6px 12px;
  margin-right: 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1em;
}

.management-actions button:not(:last-child) {
  margin-right: 10px; /* 添加右边距 */
}

.management-actions button:hover {
  background-color: #e0a800;
}

.dishes-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.dishes-table th,
.dishes-table td {
  padding: 12px;
  border-bottom: 1px solid #eaeaea;
}

.dishes-table th {
  background-color: #f8f9fa;
  color: #495057;
  font-weight: 600;
  border-bottom: 2px solid #dee2e6;
}

.dishes-table img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}

.dishes-table tr:last-child td {
  border-bottom: none;
}

.dishes-table input[type="checkbox"] {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* 当没有菜品时显示的文本样式 */
.dishes-table tr{
  text-align: center;
  color: #6c757d;
  padding: 20px;
}

.form-group {
  margin-bottom: 30px;
  font-size: 1.1em;
}
.form-group label {
  display: block;
  margin-bottom: 15px;
}
.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s;
}

.modal-content {
  background-color: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 500px; /* 可以根据实际内容宽度调整 */
  transition: transform 0.3s ease;
  transform: translateY(-50px);
}

.modal-actions {
  text-align: right;
  margin-top: 20px;
}

.confirm-button {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.cancel-button {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 8px 16px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9em;
}

.confirm-button {
  background-color: #ffc107;
  color: #212529;
  border: 1px solid #ddd;
  padding: 8px 16px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9em;
}

.cancel-button:hover {
  background-color: #e5e5e5;
}

.confirm-button:hover {
  background-color: #e0a800;
}

.modal-visible {
  opacity: 1;
  visibility: visible;
}

.modal-content-visible {
  transform: translateY(0);
}

.dishes-table td button {
  margin-right: 12px; /* 调整右侧间距 */
}

.dishes-table td button:last-child {
  margin-right: 0; /* 最后一个按钮不需要额外间距 */
}


.modal-content input[type="text"] {
  font-size: 16px; /* 调整字体大小 */
  padding: 10px; /* 可选：增加内边距以提升可读性 */
  width: 100%; /* 可选：使输入框宽度占满父容器 */
  box-sizing: border-box; /* 确保内边距不影响输入框宽度 */
}
</style>