<template>
  <div class="merchant-management">
    <!-- 左侧表单 -->
    <div class="left-panel">
      <h2 class="header-title">个人中心</h2>
      <form @submit.prevent="saveProfile" class="form-container">
        <!-- 商家名字 -->
        <div class="form-group">
          <label for="merchantName">商家名字：</label>
          <input
            type="text"
            id="merchantName"
            v-model="profile.merchantName"
            placeholder="请输入商家名字"
          />
        </div>

        <!-- 修改地址 -->
        <div class="form-group">
          <label for="address">地址：</label>
          <input
            type="text"
            id="address"
            v-model="profile.address"
            placeholder="请输入地址"
          />
        </div>

        <!-- 修改配送费 -->
        <div class="form-group">
          <label for="deliveryFee">配送费：</label>
          <select id="deliveryFee" v-model="profile.deliveryFee">
            <option value="">请选择配送费</option>
            <option v-for="fee in [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.4, 1.5, 1.6, 1.7, 1.8, 1.9, 2.0, 2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3.0, 3.1, 3.2, 3.3, 3.4, 3.5, 3.6, 3.7, 3.8, 3.9, 4.0]" :key="fee" :value="fee">
              ￥{{ fee }}
            </option>
          </select>
        </div>

        <!-- 分类 -->
        <div
          v-for="(tag, index) in profile.tags"
          :key="`tag-${index}`"
          class="form-group"
        >
          <label :for="`tag${index}`">分类{{ index + 1 }}：</label>
          <select
            :id="`tag${index}`"
            v-model="profile.tags[index]"
            :required="index === 0"
            @change="validateTagsAndLabels"
          >
            <option value="">请选择分类</option>
            <option v-for="option in tagOptions" :key="option" :value="option">
              {{ option }}
            </option>
          </select>
        </div>

        <!-- 标签 -->
        <div
          v-for="(label, index) in profile.labels"
          :key="`label-${index}`"
          class="form-group"
        >
          <label :for="`label${index}`">标签{{ index + 1 }}：</label>
          <input
            type="text"
            :id="`label${index}`"
            v-model="profile.labels[index]"
            placeholder="请输入标签"
            :required="index === 0"
            @input="validateLabel(index)"
          />
        </div>

        <!-- 商家个人简介 -->
        <div class="form-group">
          <label for="bio">商家个人简介：</label>
          <textarea
            id="bio"
            v-model="profile.bio"
            placeholder="请输入商家个人简介"
            rows="3"
          ></textarea>
        </div>
      </form>
    </div>

    <!-- 右侧上传商家头像 -->
    <div class="right-panel">
      <div class="upload-container">
        <p class="upload-title">上传商家头像：</p>
        <div class="upload-box" v-if="!profile.avatar" @click="triggerUpload">
          <span>+</span>
        </div>
        <img
          v-else
          :src="profile.avatar"
          alt="商家头像"
          class="avatar-preview"
          @click="triggerUpload"
        />
        <input
          type="file"
          id="merchantAvatar"
          @change="uploadAvatar"
          ref="fileInput"
          hidden
        />
      </div>

      <button type="submit" class="save-button" :disabled="!isFormValid" @click="saveProfile">
        保存信息
      </button>

      <!-- 动态提示 -->
      <p v-if="!isFormValid" class="error-message">
        请填写必填项：商家名字，分类1，标签1，并确保分类和标签不重复。
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      profile: {
        merchantName: "", // 新增商家名字字段
        address: "",
        deliveryFee: "",
        deliveryStart: "",
        deliveryEnd: "",
        tags: ["", "", ""],
        labels: ["", "", ""],
        avatar: null,
        bio: "", // 新增商家个人简介字段
      },
      tagOptions: [
        "推荐美食",
        "奶茶甜品",
        "汉堡炸鸡",
        "烧烤小吃",
        "水果零食",
        "特色美食",
      ],
    };
  },
  computed: {
    isFormValid() {
      // 检查表单有效性
      return (
        this.profile.merchantName && // 新增商家名字的验证
        this.profile.address &&
        this.profile.deliveryFee &&
        this.profile.tags[0] &&
        this.profile.labels[0] &&
        this.profile.labels.every((label) =>
          this.validateLabelContent(label)
        ) &&
        !this.hasDuplicate(this.profile.tags) &&
        !this.hasDuplicate(this.profile.labels)
      );
    },
  },
  mounted() {
    this.fetchMerchantProfile();
  },
  methods: {
    async fetchMerchantProfile() {
      const merchantId = this.$root.merchantId;
    try {
      const response = await axios.get(`https://eattogetchubby.top/merchant/id/${merchantId}`);
      const data = response.data;
      this.profile.merchantName = data.merchantname;
      this.profile.address = data.merchantaddress;
      this.profile.deliveryFee = data.merchantdeliverprice;
      this.profile.labels[0] = data.merchanttags1;
      this.profile.labels[1] = data.merchanttags2;
      this.profile.labels[2] = data.merchanttags3;
      this.profile.tags[0] = data.merchantcategories1;
      this.profile.tags[1] = data.merchantcategories2;
      this.profile.tags[2] = data.merchantcategories3;
      // this.profile.avatar = data.merchantpic;
      this.profile.bio = data.merchantdesc;
    } catch (error) {
      console.error("Error fetching merchant profile: ", error);
      alert("无法加载商家信息。");
    }
  },
    uploadAvatar(event) {
      const file = event.target.files[0];
      if (file) {
        this.profile.avatar = URL.createObjectURL(file);
      }
    },
    triggerUpload() {
      this.$refs.fileInput.click();
    },
    async saveProfile() {
      if (!this.isFormValid) {
        alert("请确保表单填写正确！");
        return;
      }

      const params = {
        merchantid: this.$root.merchantId,//默认商家id
        merchantname: this.profile.merchantName,
        merchantaddress: this.profile.address,
        merchantdeliverprice: this.profile.deliveryFee,
        merchanttags1: this.profile.labels[0],
        merchanttags2: this.profile.labels[1],
        merchanttags3: this.profile.labels[2],
        merchantcategories1: this.profile.tags[0],
        merchantcategories2: this.profile.tags[1],
        merchantcategories3: this.profile.tags[2],
        // merchantpic: this.profile.avatar,
        merchantdesc: this.profile.bio,
      };
      console.log(params);
      try {
        const response = await axios.put('https://eattogetchubby.top/merchant/merchantmessage', params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded' // 确保这里与后端期望的一致
          }
        });
        const message = response.data; // 假设后端返回的是一个字符串消息
        if (message === "成功！") {
          alert("商家信息更新成功！");
        } else {
          alert(message);
        }
      } catch (error) {
        console.error("Error updating merchant profile: ", error);
        alert("更新商家信息时发生错误。");
      }
    },
    hasDuplicate(array) {
      // 检查数组是否有重复值
      const filtered = array.filter((item) => item !== "");
      return new Set(filtered).size !== filtered.length;
    },
    validateTagsAndLabels() {
      if (this.hasDuplicate(this.profile.tags)) {
        alert("分类不能重复！");
        this.profile.tags = ["", "", ""];
      }
      if (this.hasDuplicate(this.profile.labels)) {
        alert("标签不能重复！");
        this.profile.labels = ["", "", ""];
      }
    },
    validateLabel(index) {
      const label = this.profile.labels[index];
      if (label && !this.validateLabelContent(label)) {
        alert("标签是小于等于六个字的中文！");
        this.profile.labels[index] = "";
      }
    },
    validateLabelContent(label) {
      // 验证标签内容：必须是小于等于六个字的中文
      return /^[\u4e00-\u9fa5]{1,6}$/.test(label);
    },
  },
};
</script>

<style scoped>
.merchant-management {
  display: flex;
  gap: 30px;
  padding: 20px;
}

.left-panel {
  flex: 2;
}

.right-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.header-title {
  margin-top: 0px;
  font-size: 2rem;
  margin-bottom: 50px;
  text-align: left;
  margin-left: 10px;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

input[type="text"],
select,
input[type="time"],
textarea {
  flex: 1;
  height: 40px;
  padding: 5px;
  font-size: 1rem;
}

/* 为地址输入框设置更窄的高度 */
input[type="text"][id="address"],
/* 为配送时间选择器设置更窄的高度 */
input[type="time"],
/* 为标签1、标签2、标签3设置更窄的高度 */
input[type="text"][id="label0"],
input[type="text"][id="label1"],
input[type="text"][id="label2"] {
  height: 25px; /* 减小高度值 */
  /* 其他样式保持不变 */
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.upload-title {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
  margin-top: 100px;
}

.upload-box {
  width: 200px;
  height: 200px;
  border: 2px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: #ccc;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
}

.upload-box:hover {
  border-color: #999;
  color: #999;
}

.avatar-preview {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  cursor: pointer;
}

.save-button {
  padding: 10px 20px;
  background: #4caf50;
  color: #fff;
border: none;
border-radius: 5px;
cursor: pointer;
margin-top: 50px;
}

.save-button:hover {
background: #45a049;
}

.error-message {
color: red;
font-size: 0.9rem;
text-align: center;
}
</style>