<template>
  <div class="forgot-password-form">
    <h2>忘记密码?</h2>
    <p>请输入您的手机号码，以重置密码：</p>
    <form @submit.prevent="onResetPassword">
      <input type="tel" placeholder="手机号码" v-model="resetForm.phone" required />
      <input type="password" placeholder="新密码" v-model="resetForm.password" required />
      <input type="password" placeholder="确认密码" v-model="resetForm.confirmPassword" required />
      <button type="submit" class="forgotPassword-button">重置密码</button>
      <button type="button" style="margin-top: 15px;" @click="$router.push('/')">返回</button>
    </form>
  </div>
</template>

<script>
  import axios from "axios";
  export default {
    name: "ForgotPassword",
    data() {
      return {
        resetForm: {
          phone: "",
          password: "",
          confirmPassword: "",
        },
      };
    },
    methods: {
      async onResetPassword() {
        // 检验手机号是否为11位
        if (this.resetForm.phone.length !== 11) {
          alert("请输入正确的11位手机号码");
          return;
        }
        // 检验密码最少长度为6位字符
        if (this.resetForm.password.length < 6) {
          alert("密码长度不能少于6位字符");
          return;
        }
        // 检验密码与确认密码是否一致
        if (this.resetForm.password !== this.resetForm.confirmPassword) {
          alert("密码和确认密码不一致");
          return;
        }
        // 在这里处理重置密码的逻辑
        // 检查手机号是否已注册
      try {
        const existsResponse = await axios.get("https://eattogetchubby.top/merchant/exists1", {
          params: {
            merchantphonenumber: this.resetForm.phone
          }
        });
        // 根据返回结果进行判断
        if (!existsResponse.data) {
          alert("该电话号码尚未注册，请先注册！");
          return;
        }
        try {
        // 发送重置密码的请求到服务器...
        // 新密码在数据库中进行修改
        const response = await axios.put('https://eattogetchubby.top/merchant/changepassword', {
            merchantphonenumber: this.resetForm.phone, // 假设后端使用手机号作为用户名
          merchantpassword: this.resetForm.password
        }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        });

        if (response.data === '成功！') {
          alert('密码更新成功！');
          console.log("请求重置密码的手机号码", this.resetForm.phone);
        // 假设重置成功，进行路由跳转
        this.$router.push("/");
        } else {
          alert('密码更新失败：' + response.data);
        }
      } catch (error) {
        console.error("更新密码时出错", error);
        alert("密码更新失败，请重试！");
      }
        
      } catch (error) {
        console.error("检查商家手机号时出错", error);
        alert("无法检查到商家手机号，请重试！");
        return;
      }
      },
    },
  };
  </script>

<style scoped>
.forgot-password-form {
  text-align: left;
  margin-top: 50px;
  margin-left:185px;
  width: 50%;
}

.forgot-password-form h2 {
  font-size: 2.7em;
  margin-bottom: 25px;
}

.forgot-password-form p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.forgot-password-form input[type="tel"],
.forgot-password-form input[type="password"] {
  width: 430px;
  padding: 10px;
  font-size: 1.2em;
  border-radius: 10px;
  background-color: #000;
  border: 1px solid #fff;
  color: #fff;
  margin-bottom: 20px;
}

.forgotPassword-button {
  width: 450px;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  border: 2px solid #000000;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.forgotPassword-button:hover {
  background-color: #000;
  color: #ffffff;
  border: 2px solid #5b5b5b;
}

button {
  width: 450px;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #000000;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}

button:hover {
  background-color: #000;
  color: #ffffff;
  border: 2px solid #5b5b5b;
}
</style>
