<template>
  <div id="app">
    <!-- 使用 v-slot="{ Component }" 来接收从 router-view 传递的 Component -->
    <router-view v-slot="{ Component }">
      <!-- 使用 v-bind:is 来动态绑定组件 -->
      <component :is="Component" />
    </router-view>
  </div>
</template>

<script>
export default {
  name: "App"
  // 不需要 methods，除非您有其他需要添加的方法
};
</script>

<style>
/* 这里可以放置全局样式 */
</style>
