<!-- src/views/Order.vue -->
<template>
  <div class="dishes-wrapper">
    <!-- 订单分类导航栏 -->
    <div class="order-categories">
      <button
        v-for="(category, index) in categories"
        :key="index"
        :class="{ active: activeCategory === category.value }"
        @click="setActiveCategory(category.value)"
      >
        {{ category.label }}
        <span
          v-if="
            (category.value === 'pending' || category.value === 'toDeliver') &&
            orderCounts[category.value]
          "
          class="badge"
        >
          {{ orderCounts[category.value] }}
        </span>
      </button>
    </div>

    <!-- 订单列表 -->
    <div class="table-container">
      <!-- 搜索区域 -->
      <div class="search-section">
        <!-- 搜索提示文字 -->
        <label for="order-id">订单号：</label>
        <input id="order-id" type="text" v-model="searchParams.orderId" />

        <label for="phone">手机号：</label>
        <input id="phone" type="text" v-model="searchParams.phone" />

        <label for="date">下单时间：</label>
        <input id="date" type="date" v-model="searchParams.startDate" />

        <span class="to-label">至</span>

        <input id="end-date" type="date" v-model="searchParams.endDate" />

        <button @click="onSearchButtonClick" class="search-btn">查询</button>
      </div>

      <table class="orders-table">
        <thead>
          <tr>
            <th>订单号</th>
            <th>订单状态</th>
            <th>用户名</th>
            <th>手机号</th>
            <th>地址</th>
            <th v-if="activeCategory === 'all'">下单时间</th>
            <th v-else>备注</th>
            <th>实收金额</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="order in filteredOrders" :key="order.id">
            <td>{{ order.id }}</td>
            <td>{{ order.status }}</td>
            <td>{{ order.username }}</td>
            <td>{{ order.phone }}</td>
            <td>{{ order.address }}</td>
            <td v-if="activeCategory === 'all'">{{ order.orderTime }}</td>
            <td v-else>{{ order.note }}</td>
            <td>¥{{ order.amount }}</td>
            <td>
              <a
                v-if="order.status === '待接单'"
                href="#"
                @click.prevent="rejectOrder(order)"
                class="reject"
                >拒单</a
              >
              <a
                v-if="order.status === '待接单'"
                href="#"
                @click.prevent="acceptOrder(order)"
                >接单</a
              >
              <a
                v-if="order.status === '已接单'"
                href="#"
                @click.prevent="cancelOrder(order)"
                class="cancel"
                >取消</a
              >
              <a
      v-if="order.address === '堂食' && order.status === '已接单'"
      href="#"
      @click.prevent="dispatchOrder(order)"
    >
      已做好
    </a>
              <a
                v-if="order.status === '派送中'"
                href="#"
                @click.prevent="deliverOrder(order)"
                >送达</a
              >
              <a href="#" @click.prevent="openOrderDetails(order)">查看</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- 弹窗 -->
    <div v-if="isModalVisible" class="modal-overlay">
      <div class="modal">
        <h2>订单信息</h2>
        <div class="modal-content">
          <div class="order-header">
            <div><strong>订单号:</strong> {{ selectedOrder.id }}</div>
            <div class="badge">{{ selectedOrder.status }}</div>
            <div><strong>下单时间:</strong> {{ selectedOrder.orderTime }}</div>
          </div>
          <hr />
          <div class="order-detail">
            <div class="info-row">
              <div><strong>用户名:</strong> {{ selectedOrder.username }}</div>
              <div><strong>手机号:</strong> {{ selectedOrder.phone }}</div>
            </div>
            <div class="info-row">
              <div>
                <strong>预计送达时间:</strong>
                {{ selectedOrder.estimatedDeliveryTime }}
              </div>
            </div>
            <div class="info-row">
              <div><strong>地址:</strong> {{ selectedOrder.address }}</div>
            </div>
            <div class="note-section">
              <strong>备注:</strong>
              <textarea
                v-model="selectedOrder.note"
                placeholder="新增备注..."
              ></textarea>
            </div>
            <hr />
            <div class="order-items">
              <div><strong>菜品:</strong></div>
              <div class="dishes-list">
                <div v-for="(dish, index) in selectedOrder.dishes" :key="index" class="dish-item" :title="dish.dishfoodname">
                  <span>{{ dish.dishfoodname }}</span>
                  <span>x{{ dish.dishfoodquantity }}</span>
                  <span>¥{{ dish.dishfoodprice }}</span>
                </div>
              </div>
              <div class="subtotal">
                <strong>菜品小计:</strong>
                <span class="total-cost">¥{{ selectedOrder.foodCost }}</span>
              </div>
            </div>
            <hr />
            <div><strong>费用:</strong></div>
            <div class="cost-section">
              <div>菜品费用: ¥{{ selectedOrder.foodCost }}</div>
              <div>派送费: ¥{{ selectedOrder.deliveryFee }}</div>
              <div>打包费: ¥{{ selectedOrder.packagingFee }}</div>
              <div>
                合计:
                <span class="total-cost">¥{{ selectedOrder.totalCost }}</span>
              </div>
              <div>支付渠道: {{ selectedOrder.paymentChannel }}</div>
              <div>支付时间: {{ selectedOrder.paymentTime }}</div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="close-btn" @click="closeModal">关闭</button>
            <button
              v-if="selectedOrder.status === '待接单'"
              class="accept-btn"
              @click="acceptCurrentOrder"
            >
              接单
            </button>
            <button
              v-if="selectedOrder.status === '待接单'"
              class="reject-btn"
              @click="rejectCurrentOrder"
            >
              拒单
            </button>
            <button
  v-if="selectedOrder.status === '已接单' && selectedOrder.address === '堂食'"
  class="dispatch-btn"
  @click="dispatchCurrentOrder"
>
  已做好
</button>
            <button
              v-if="selectedOrder.status === '已接单'"
              class="cancel-btn"
              @click="cancelCurrentOrder"
            >
              取消
            </button>
            <button
              v-if="selectedOrder.status === '派送中'"
              class="deliver-btn"
              @click="deliverCurrentOrder"
            >
              送达
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
export default {
  data() {
    return {
      activeCategory: "all",
      categories: [
        { label: "全部订单", value: "all" },
        { label: "待接单", value: "pending" },
        { label: "已接单", value: "toDeliver" },
        { label: "骑手正在赶往商家", value: "tomerchant" },
        { label: "待自取", value: "wait" },
        { label: "派送中", value: "delivering" },
        { label: "已完成", value: "completed" },
        { label: "已取消", value: "canceled" },
      ],
      orders: [],
      isModalVisible: false,
      selectedOrder: {},
      searchParams: {
        orderId: "",
        phone: "",
        startDate: "",
        endDate: "",
      },
      filteredOrders: [],
    };
  },

  mounted() {
    // 初始显示所有订单
    this.filteredOrders = this.orders;
    this.fetchOrders();
  },

  computed: {
    orderCounts() {
      const counts = {
        all: this.orders.length,
        pending: 0,
        toDeliver: 0,
        delivering: 0,
        completed: 0,
        canceled: 0,
      };
      this.orders.forEach((order) => {
        if (order.status === "待接单") counts.pending++;
        else if (order.status === "已接单") counts.toDeliver++;
        else if (order.status === "派送中") counts.delivering++;
        else if (order.status === "已完成") counts.completed++;
        else if (order.status === "已取消") counts.canceled++;
      });
      return counts;
    },
  },
  methods: {
    async fetchOrders() {
      const merchantid = this.$root.merchantId;
      try {
        const response = await axios.get(`https://eattogetchubby.top/order/merchantid/${merchantid}`);
        this.orders = await Promise.all(response.data.map(async (order) => {
          const dishesResponse = await axios.get(`https://eattogetchubby.top/dish/${order.orderid}`);
          return {
            id: order.orderid,
            status: order.orderstatus,
            userid: order.userid,
            username: order.customername,
            phone: order.customerphonenumber,
            address: order.customeraddress,
            orderTime: order.ordertime,
            amount: order.orderprice,
            estimatedDeliveryTime: order.eattime,
            dishes: dishesResponse.data, // 确保这里接收的是Dish对象的数组
            foodCost: order.orderprice-order.orderdeliverprice,
            deliveryFee: order.orderdeliverprice,
            packagingFee: 0,
            totalCost: order.orderprice,
            paymentChannel: "微信",
            paymentTime: order.ordertime,
            note: order.orderremake,
          };
        }));
        this.filteredOrders = [...this.orders];
      } catch (error) {
        console.error("Error fetching orders: ", error);
        alert("无法加载订单数据。");
      }
    },
    setActiveCategory(category) {
      this.activeCategory = category;
      this.searchOrders(); // 更新 filteredOrders，根据分类过滤
    },

    searchOrders() {
      // 首先根据 activeCategory 进行过滤
      let results = this.orders.filter((order) => {
        switch (this.activeCategory) {
          case "pending":
            return order.status === "待接单";
          case "toDeliver":
            return order.status === "已接单";
            case "tomerchant":
            return order.status === "骑手正在赶往商家";
            case "wait":
            return order.status === "待自取";
          case "delivering":
            return order.status === "派送中";
          case "completed":
            return order.status === "已完成";
          case "canceled":
            return order.status === "已取消";
          default:
            return true; // activeCategory === 'all'
        }
      });

      // 如果存在搜索参数，则进一步过滤结果
      if (
        this.searchParams.orderId ||
        this.searchParams.phone ||
        this.searchParams.startDate ||
        this.searchParams.endDate
      ) {
        results = results.filter((order) => {
          const matchesOrderId =
        !this.searchParams.orderId ||
        order.id.toString().includes(this.searchParams.orderId); // 将 order.id 转换为字符串
          const matchesPhone =
            !this.searchParams.phone ||
            order.phone.includes(this.searchParams.phone);
          const matchesDateRange =
            (!this.searchParams.startDate ||
              new Date(order.orderTime) >=
                new Date(this.searchParams.startDate)) &&
            (!this.searchParams.endDate ||
              new Date(order.orderTime) <= new Date(this.searchParams.endDate));

          return matchesOrderId && matchesPhone && matchesDateRange;
        });
      }

      // 更新filteredOrders以存储过滤后的订单
      this.filteredOrders = results;
    },

    onSearchButtonClick() {
      this.searchOrders();
    },
    changeStatus(order) {
      axios.put(`https://eattogetchubby.top/order/status`, {
        orderid: order.id,
        orderstatus: order.status
      }, {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
      .then(response => {
        alert(response.data);
        this.fetchOrders();
      })
      .catch(error => {
        console.error('Error updating order status:', error);
        alert('订单状态更新失败');
      });
    },
    acceptOrder(order) {
      order.status = "已接单";
      this.changeStatus(order);
      this.activeCategory = "all";

    },
    rejectOrder(order) {
      order.status = "已取消";
      this.changeStatus(order);
      this.activeCategory = "all";

    },
    cancelOrder(order) {
      order.status = "已取消";
      this.changeStatus(order);
      this.activeCategory = "all";

    },
    dispatchOrder(order) {
    // 根据地址或其它属性设置新的状态
    if (order.address === '堂食') {
        // 用户选择 "已做好"，将状态改为 "待自取"
        order.status = "待自取";
    } else {
        // 用户选择"派送"，将状态改为 "派送中"
        order.status = "派送中";
    }

    // 假设您有一个方法来更新状态
    this.changeStatus(order); // 触发对状态的更新，需要实现此逻辑
    this.activeCategory = "all"; // 修改当前分类状态，如果需要的话
},
    
    deliverOrder(order) {
      order.status = "已完成";
      this.changeStatus(order);
      this.activeCategory = "all";
    },
    openOrderDetails(order) {
      this.selectedOrder = order;
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },
    acceptCurrentOrder() {
      if (this.selectedOrder.status === "待接单") {
        this.selectedOrder.status = "已接单";
        // this.changeStatus(order);
        this.closeModal(); // 关闭弹窗
      }
    },

    rejectCurrentOrder() {
      if (this.selectedOrder.status === "待接单") {
        this.selectedOrder.status = "已取消";
        // this.changeStatus(order);
        this.closeModal(); // 关闭弹窗
      }
    },

    dispatchCurrentOrder() {
      if (this.selectedOrder.status === "已接单") {
        this.selectedOrder.status = "派送中";
        // this.changeStatus(order);
        this.closeModal(); // 关闭弹窗
      }
    },

    cancelCurrentOrder() {
      if (this.selectedOrder.status === "已接单") {
        this.selectedOrder.status = "已取消";
        // this.changeStatus(order);
        this.closeModal(); // 关闭弹窗
      }
    },

    deliverCurrentOrder() {
      if (this.selectedOrder.status === "派送中") {
        this.selectedOrder.status = "已完成";
        // this.changeStatus(order);
        this.closeModal(); // 关闭弹窗
      }
    },
  },
};
</script>


<style scoped>
/* 订单分类导航栏样式 */
.order-categories {
  display: flex;
  margin-bottom: 20px;
}
.order-categories button {
  background-color: #f5f5f5;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  margin-right: 10px;
  border-radius: 5px;
  color: #333;
  position: relative;
}
.order-categories button:hover {
  background-color: #ffe992;
}
.order-categories button.active {
  background-color: #ffcc00;
  color: #000000;
}
.order-categories button .badge {
  background-color: #ff4d4f;
  color: #fff;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  position: absolute;
  top: 5px;
  right: 10px;
}

/* 表格样式 */
.dishes-wrapper {
  height: calc(100vh - 100px);
  margin-left: 200px;
  padding-top: 20px;
  overflow-y: auto;
  padding-right: 10px;
  margin-right: 0px;
  margin-top: 75px;
}
/* 表格容器，设置固定宽度和自动增长的高度 */
.table-container {
  width: 100%;
  max-width: 1750px; /* 设置表格的最大宽度 */
  overflow-x: auto; /* 防止内容超出 */
  margin: 0 auto;
}

.orders-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.orders-table th,
.orders-table td {
  padding: 16px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  overflow: visible; /* 允许内容溢出 */
  white-space: normal; /* 允许文本换行 */
  text-overflow: clip; /* 不截断文本 */
}

.orders-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}

.orders-table th:nth-child(1),
.orders-table td:nth-child(1) {
  width: 15%;
}

.orders-table th:nth-child(2),
.orders-table td:nth-child(2) {
  width: 10%;
}

.orders-table th:nth-child(3),
.orders-table td:nth-child(3) {
  width: 7%;
}

.orders-table th:nth-child(4),
.orders-table td:nth-child(4) {
  width: 10%;
}

.orders-table th:nth-child(5),
.orders-table td:nth-child(5) {
  width: 10%;
  word-wrap: break-word;
  white-space: normal;
}

.orders-table th:nth-child(6),
.orders-table td:nth-child(6) {
  width: 10%;
  word-wrap: break-word;
  white-space: normal;
}

.orders-table th:nth-child(7),
.orders-table td:nth-child(7) {
  width: 10%;
  text-align: center;
}

.orders-table th:nth-child(8) {
  width: 10%;
  text-align: center;
}
.orders-table td:nth-child(8) {
  text-align: right;
  padding: 2px;
}

.orders-table td a {
  margin-right: 13px;
  color: #007bff;
  text-decoration: none;
}

.orders-table td a:hover {
  text-decoration: underline;
}

.orders-table td a.dispatch {
  color: #007bff;
  padding: 5px 10px;
  border-radius: 5px;
}

.orders-table td a.cancel {
  color: #ff4d4f;
}

.orders-table td a.reject {
  color: #ff4d4f;
}

/* 弹窗样式 */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.badge {
  background-color: #f0f0f0;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 14px;
  color: #333;
}

.info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.note-section {
  margin-bottom: 10px;
}

.note-section textarea {
  width: 100%;
  height: 50px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}

.dishes-list {
  display: block; /* 或者使用 flex; flex-direction: column; */
  margin-bottom: 5px;
  max-height: 150px; /* 或者你希望的任何高度 */
  overflow-y: auto; /* 允许垂直滚动 */
  overflow-x: hidden; /* 隐藏水平滚动条 */
  /*  这里不再使用 white-space: nowrap;，让文本可以换行 */
}

.dish-item {
  display: flex; /* 保留 flex 布局样式，使内部信息可以排列 */
  justify-content: space-between; /* 子元素之间均匀分配空间 */
  margin-bottom: 5px; /* 每个盘子项的下边距 */
  white-space: nowrap; /* 禁止换行，内部内容若超过宽度会出现省略号 */
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 使用省略号表示被截断的文本 */
  /* 添加以下行以确保宽度处理得当 */
  width: 100%; /* 确保每一项占满全宽 */
  padding: 5px; /* 可选：为每个 dish-item 添加内边距 */
  box-sizing: border-box; /* 确保 padding 不会影响元素总宽度 */
  border-bottom: 1px solid #ccc; /* 可选：添加底部边框 */
}

.subtotal {
  font-weight: bold;
  color: #ff4d4f;
  margin-top: 10px;
}

.cost-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
}

.cost-section div {
  width: 45%;
  margin-bottom: 10px;
}

.total-cost {
  font-weight: bold;
  color: #ff4d4f;
  
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.close-btn {
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 8px 16px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9em;
}

.close-btn:hover {
  background-color: #e5e5e5;

}

.accept-btn,
.dispatch-btn,
.deliver-btn {
  background-color: #ffc107;
  color: #212529;
  border: 1px solid #ddd;
  padding: 8px 16px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9em;
}

.accept-btn:hover,
.dispatch-btn:hover,
.deliver-btn:hover {
  background-color: #e0a800;
}

.reject-btn,
.cancel-btn {
  background-color: #ff4d4f;
  color: #212529;
  border: 1px solid #ddd;
  padding: 8px 16px;
  margin-left: 10px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 0.9em;
}

.reject-btn:hover,
.cancel-btn:hover {
  background-color: #ca2f31;

}

.search-section {
  display: flex;
  align-items: center;
  gap: 20px; /* 控制元素之间的间距 */
  margin-bottom: 20px;
  margin-left: 15px;
}

.search-section label {
  margin-right: 5px;
}

.search-section input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.search-section .to-label {
  margin: 0 10px;
}

.search-btn {
  background-color: #000;
  color: #fff;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>