<template>
  <header class="header">
    <img src="@/assets/logo2.png" alt="Logo" class="logo" />
    <h2 :class="isOpen ? 'open' : 'closed'">
      营业状态：{{ isOpen ? "营业中" : "打烊中" }}
    </h2>

    <button @click="showStatusModal" class="status-button">设置营业状态</button>
    <button @click="logout" class="logout-button">退出登录</button>

    <!-- 营业状态设置 Modal -->
    <div v-if="statusModalVisible" class="modal">
      <div class="modal-content">
        <h3>营业状态设置</h3>
        <div class="status-option-container">
          <!-- 营业中 -->
          <div
            class="status-option"
            :class="{ active: tempIsOpen }"
            @click="selectStatus(true)"
          >
            <div class="status-toggle">
              <input type="radio" id="openStatus" :checked="tempIsOpen" />
              <label for="openStatus" class="status-label">营业中</label>
            </div>
            <label for="openStatus" class="status-description">
              当前餐厅处于营业状态，自动接受任何订单
            </label>
          </div>

          <!-- 打烊中 -->
          <div
            class="status-option"
            :class="{ active: !tempIsOpen }"
            @click="selectStatus(false)"
          >
            <div class="status-toggle">
              <input type="radio" id="closedStatus" :checked="!tempIsOpen" />
              <label for="closedStatus" class="status-label">打烊中</label>
            </div>
            <label for="closedStatus" class="status-description">
              当前餐厅处于打烊状态，仅接受预约订单
            </label>
          </div>
        </div>

        <div class="modal-actions">
          <button @click="cancelStatus" class="cancel-button">取消</button>
          <button @click="confirmStatus" class="confirm-button">确定</button>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      statusModalVisible: false, // 控制营业状态设置弹窗显示
      isOpen: true, // 当前营业状态
      tempIsOpen: true, // 临时营业状态
    };
  },
  created() {
    // 组件创建时获取商家状态
    this.fetchMerchantStatus();
  },
  methods: {
    async fetchMerchantStatus() {
      const merchantId = this.$root.merchantId; // 获取商家 ID
      try {
        const response = await axios.get(`https://eattogetchubby.top/merchant/status1/${merchantId}`);
        // 假设返回的响应中 merchantStatus 为 1 或 0
        this.isOpen = response.data.merchantStatus === 1; // 如果返回值为 1 则为营业中
        this.tempIsOpen = this.isOpen; // 同步临时状态
      } catch (error) {
        console.error("获取商家状态时出错:", error);
      }
    },
    logout() {
      console.log("执行退出登录逻辑...");
      this.dropdownVisible = false;
      this.$router.push('/');
    },
    showStatusModal() {
      this.statusModalVisible = true;
      this.tempIsOpen = this.isOpen; // 根据当前状态设置临时状态
    },
    selectStatus(isOpen) {
      // 通过参数控制临时状态
      this.tempIsOpen = isOpen;
    },
    cancelStatus() {
      this.statusModalVisible = false;
    },
  
    async confirmStatus() {
  // 检查当前状态与选择的临时状态是否相同
  if (this.isOpen === this.tempIsOpen) {
    alert(`当前状态已经是 ${this.isOpen ? "营业中" : "打烊中"}，无需再次设置。`);
    return; // 结束方法，不执行后续逻辑
  }
  
  // 更新本地状态
  this.isOpen = this.tempIsOpen; 
  this.statusModalVisible = false; // 隐藏状态设置弹窗

  // 数据库更新逻辑
  const merchantId = this.$root.merchantId; // 获取商家 ID
  const merchantStatus = this.isOpen ? 1 : 0; // 如果营业就设置为 1, 打烊就设置为 0

  try {
    const response = await this.updateMerchantStatus(merchantId, merchantStatus);
    console.log("商家状态已更新:", response.data);
  } catch (error) {
    console.error("更新商家状态时出错:", error);
  }
},

    async updateMerchantStatus(id, status) {
      const response = await axios.put(`https://eattogetchubby.top/merchant/updatestatus/${id}`, {
        merchantStatus: status,
      });
      return response; // 返回响应
    },
    
  },
};
</script>

<style scoped>
.header {
  justify-content: space-between; /* 保持左右对齐 */
  width: 100%;
  background-color: #ffffe7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.open {
  background-color: rgb(181, 53, 53);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font: 1.2em sans-serif;
}

.closed {
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font: 1.2em sans-serif;
}

.logo {
  width: 160px;
  height: auto;
  margin-left: 4px;
}

h2 {
  background-color: rgb(65, 65, 65);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font: 1.2em sans-serif;
  margin-right: 1000px;
}

.status-button {
  background-color: #ffcc00;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
}

.status-button:hover {
  background-color: #e0a800;
}

.logout-button {
  background-color: rgb(181, 53, 53);
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 3%;
}

.logout-button:hover {
  color: black;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content {
  background: white;
  padding: 30px;
  border-radius: 8px;
  width: 400px;
  text-align: left;
}

.modal-actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.modal-actions button {
  margin: 0 5px;
}

.status-option-container {
  display: flex;
  flex-direction: column;
}

.status-option {
  border: 2px solid gray;
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  transition: background-color 0.3s, border-color 0.3s;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer; /* 鼠标悬停时的样式 */
}

.status-option:hover {
  border-color: orange; /* 悬停边框颜色 */
  background-color: #ffe6b3; /* 悬停背景色 */
}

.status-toggle {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.status-label {
  font-size: 1.2em;
  margin-left: 8px;
  font-weight: bold;
  user-select: none;
}

.status-description {
  color: #2f2f2f;
  font-size: 1em;
  user-select: none;
  margin-top: 10px;
}

.status-option.active {
  background-color: #fffdd0; /* 选中时的背景色 */
  border-color: orange;
}

.cancel-button {
  background-color: #f44336;
  color: white;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  border: 1px solid #c8c8c8; /* 黑色边框 */
}

.confirm-button {
  background-color: #ffcc00;
  color: black;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  border: 1px solid #c8c8c8; /* 黑色边框 */
}
</style>