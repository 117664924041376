<template>
  <div class="register-form">
    <h2>注册新账号</h2>
    <p>请输入您的手机号码和密码进行注册：</p>
    <form @submit.prevent="onRegister">
      <input
        type="text"
        placeholder="用户名"
        v-model="registerForm.name"
        required
      />
      <input
        type="tel"
        placeholder="手机号码"
        v-model="registerForm.phone"
        required
      />
      <input
        type="password"
        placeholder="密码"
        v-model="registerForm.password"
        required
      />
      <input
        type="password"
        placeholder="确认密码"
        v-model="registerForm.confirmPassword"
        required
      />
      <button type="submit" class="register-button">注册</button>
      <button
        type="button"
        style="margin-top: 15px"
        @click="$router.push('/')"
      >
        返回
      </button>
    </form>
  </div>
</template>

<script>
import axios from "axios"; // 引入axios
export default {
  name: "UserRegister",
  data() {
    return {
      registerForm: {
        name: "",
        phone: "",
        password: "",
        confirmPassword: "",
      },
    };
  },
  methods: {
    async onRegister() {
      // 检验手机号是否为11位
      if (this.registerForm.phone.length !== 11) {
        alert("请输入正确的11位手机号码");
        return;
      }
      // 检验密码最少长度为6位字符
      if (this.registerForm.password.length < 6) {
        alert("密码长度不能少于6位字符");
        return;
      }
      // 校验密码和确认密码是否一致
      if (this.registerForm.password !== this.registerForm.confirmPassword) {
          alert("密码和确认密码不一致");
          return;
      }

      // 检查商家名称是否已存在
      try {
          const existsResponse = await axios.get("https://eattogetchubby.top/merchant/exists", {
              params: {
                  merchantname: this.registerForm.name
              }
          });
          
          // 根据返回结果进行判断
          if (existsResponse.data) {
              alert("商家名称已存在，请使用其他名称");
              return;
          }
      } catch (error) {
          console.error("检查商家名称时出错", error);
          alert("无法检查商家名称，请重试！");
          return;
      }

      // 如果商家名称不存在，发送注册请求
      try {
          const requestData = {
              merchantname: this.registerForm.name,
              merchantphonenumber: this.registerForm.phone,
              merchantpassword: this.registerForm.password,
          };

          const response = await axios.post("https://eattogetchubby.top/merchant/register", requestData);
          
          alert("注册成功：" + response.data);
      } catch (error) {
          console.error("注册失败", error);
          alert("注册失败，请重试！");
      }
      // 假设注册成功，进行路由跳转
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.register-form {
  text-align: left;
  width: 50%;
  margin-top: 50px;
  margin-left:185px;
}

.register-form h2 {
  font-size: 2.7em;
  margin-bottom: 25px;
}

.register-form p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.register-form input[type="text"],
.register-form input[type="tel"],
.register-form input[type="password"] {
  width: 430px;
  padding: 10px;
  font-size: 1.2em;
  border-radius: 10px;
  background-color: #000;
  border: 1px solid #fff;
  color: #fff;
  margin-bottom: 20px;
}

.register-button {
  width: 450px;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
  background-color: #fff;
  color: #000;
  border: 2px solid #000000;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.register-button:hover {
  background-color: #000;
  color: #ffffff;
  border: 2px solid #5b5b5b;
}

button {
  width: 450px;
  padding: 10px;
  font-size: 1em;
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #000000;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
}

button:hover {
  background-color: #000;
  color: #ffffff;
  border: 2px solid #5b5b5b;
}
</style>
