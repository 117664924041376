<template>
  <div class="menu-summary">
    <div class="header">
      <span class="title">菜品总览</span>
      <span class="date">{{ currentDate }}</span>
      <button class="manage-button" @click="goToMenuManagement">菜品管理</button>
    </div>
    <div class="data-grid">
      <div class="data-item">
        <p class="data-label">已启售</p>
        <p class="data-value">24</p>
      </div>
      <div class="data-item">
        <p class="data-label">已停售</p>
        <p class="data-value">1</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'

// 获取当前日期
const currentDate = ref(new Date().toISOString().split('T')[0])

const router = useRouter()

function goToMenuManagement() {
  router.push({ path: '/dashboard/dishes' });
}

</script>

<style scoped>
.menu-summary {
  background-color: #fff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 48%; /* 控制组件宽度为页面一半 */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: bold;
  font-size: 1.2em;
}

.manage-button {
  background-color: #ffcc00;
  color: rgb(0, 0, 0);
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
}

.manage-button:hover {
  background-color: #e6b800;
}

.data-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr) auto; 
  gap: 10px;
  align-items: center;
}

.data-item {
  background-color: #ffffe7;
  border-radius: 5px;
  text-align: center;
  padding: 10px;

}

.data-label {
  color: #666;
  font-size: 0.9em;
  margin-bottom: 5px;
}

.data-value {
  font-weight: bold;
  font-size: 1.1em;
  color: #333;
}

.date {
  color: #888;
  font-size: 0.9em;
  margin-left: auto;
  margin-right: 15px;
}

</style>