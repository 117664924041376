<!-- src/components/SidebarMenu.vue -->
<template>
  <nav class="sidebar">
    <img src="@/assets/chubby_w.png" alt="Logo" class="logo" />
    <div class="menu-item" @click="navigateTo('/dashboard')">工作台</div>
    <div class="menu-item" @click="navigateTo('/dashboard/orders')">订单管理</div>
    <div class="menu-item" @click="navigateTo('/dashboard/dishes')">菜品管理</div>
    <div class="menu-item" @click="navigateTo('/dashboard/personal')">个人中心</div>
    <!-- 其他菜单项 -->
  </nav>
</template>

<script setup>
import { useRouter } from 'vue-router'

const router = useRouter()
function navigateTo(path) {
  router.push(path)
}
</script>

<style scoped>
.sidebar {
  width: 200px;
  background-color: #333;
  color: #fff;
  padding-top: 100px;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px); /* 除去顶部栏高度的剩余高度 */
  margin-top: 60px; /* 与顶部栏高度一致 */
  position: fixed; /* 固定在左侧 */
}

.logo {
  width: 160px; /* 控制logo的宽度，可以根据需要调整 */
  height: auto; /* 保持纵横比 */
  margin-top: -41px;
  margin-left: 20px;
  margin-bottom: 10px;
  border-radius: 12px;
  box-shadow: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.menu-item {
  margin: 10px 20px;
  padding: 12px;
  cursor: pointer;
  border-radius: 5px;
}

.menu-item:hover {
  background-color: #444;
}
</style>
