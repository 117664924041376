// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from './views/Dashboard.vue';
import Orders from './views/Orders.vue';
import Dishes from './views/Dishes.vue';
import Personal from './views/Personal.vue';
import UserLogin from './components/UserLogin.vue';
import UserRegister from './components/UserRegister.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import AppLayout from './layouts/AppLayout.vue';
import DashboardLayout from './layouts/DashboardLayout.vue';

const routes = [
  {
    path: '/',
    component: AppLayout,
    children: [
      { path: '', component: UserLogin },
      { path: 'register', component: UserRegister },
      { path: 'forgotPassword', component: ForgotPassword }
    ]
  },
  {
    path: '/dashboard',
    component: DashboardLayout,
    children: [
      { path: '', component: Dashboard },
      { path: 'orders', component: Orders },
      { path: 'dishes', component: Dishes },
      { path: 'personal', component: Personal }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
